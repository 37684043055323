<template>
  <div>
    <actions
      :selected-keywords-present="selectedKeywordsPresent"
      :tags="tags"
      :current-user="currentUser"
      @add-tags="addTags"
      @remove-tags="removeTags"
      @delete-keywords="deleteKeywords"
      @export-selected-keywords="exportSelectedKeywords"
      @export-semantic-selected-keywords="exportSemanticSelectedKeywords"
      @select-all-rows="selectAllRows"
    >
    </actions>
    <ag-grid-vue
      style="height: 800px"
      class="ag-theme-material"
      :columnDefs="columnDefs"
      :grid-options="gridOptions"
      :resizable="true"
      :sorting="true"
      :filter="true"
      :enableRangeSelection="true"
      :copyHeadersToClipboard="false"
      :suppressCopyRowsToClipboard="true"
      rowSelection="multiple"
      :suppressRowClickSelection="true"
      :suppressCellSelection="true"
      :suppressContextMenu="true"
      :pagination="true"
      :paginationPageSize="400"
      :cacheBlockSize="400"
      :localeTextFunc="localeTextFunc"
      @pagination-changed="onPaginationChanged"
      :suppressPaginationPanel="true"
      rowModelType="serverSide"
      @grid-ready="onGridReady"
      @first-data-rendered="adjustGrid"
      @row-data-changed="adjustGrid"
      @selection-changed="onSelectionChanged"
      :context="context"
    >
    </ag-grid-vue>
    <div class="grid-pagination">
      <span id="numberEntities">{{ firstIndex }} - {{ lastIndex }}</span>
      <el-divider
        direction="vertical"
        class="divider"
      ></el-divider>
      <el-button
        size="mini"
        @click="firstPage()"
        :disabled="firstPageDisabled"
        ><i class="fas fa-angle-double-left"></i
      ></el-button>
      <el-button
        size="mini"
        @click="previousPage()"
        :disabled="firstPageDisabled"
        ><i class="fas fa-angle-left"></i
      ></el-button>
      <span
        class="value-page"
        id="lbCurrentPage"
        >Page {{ pageNumber }}</span
      >
      <el-button
        size="mini"
        @click="nextPage()"
        :disabled="nextPageDisabled"
        ><i class="fas fa-angle-right"></i>
      </el-button>
      <el-button
        id="lastPage"
        @click="lastPage()"
        size="mini"
        :disabled="lastPageDisabled || nextPageDisabled"
        ><i class="fas fa-angle-double-right"></i
      ></el-button>
    </div>
  </div>
</template>

<script>
import { epochDayToDateTime } from "@/javascripts/dateHelpers";
import Actions from "@/pages/Keywords/AllKeywords/Grid/Actions";
import KeywordRenderer from "@/components/gridRenderers/KeywordRenderer";
import RichResultsRenderer from "@/components/gridRenderers/RichResultsRenderer";
import PageRenderer from "@/components/gridRenderers/PageRenderer";
import PositionRenderer from "@/components/gridRenderers/PositionRenderer";
import ActionsRenderer from "@/components/gridRenderers/ActionsRenderer";
import OpportunityScoreRenderer from "@/components/gridRenderers/OpportunityScoreRenderer";
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import { agGridMixin } from "@/mixins/agGridMixin";
import { gridColumnsToAPITranslation } from "@/mixins/gridColumnsToAPITranslation";
import { gridPagination } from "@/mixins/gridPagination";

import { RepositoryFactory } from "@/services/repositoryFactory";
import { formatDateWithDashes } from "@/javascripts/formatDate";
import { i18n } from "element-ui/lib/locale";

const RemoveKeywordsRepository = RepositoryFactory.get("removeKeywords");
const AddTagsRepository = RepositoryFactory.get("addTags");
const RemoveTagsRepository = RepositoryFactory.get("removeTags");
const KeywordPagesRepository = RepositoryFactory.get("keywordPages");
const KeywordsExportsRepository = RepositoryFactory.get("keywordsExports");

export default {
  mixins: [agGridMixin, gridPagination, gridColumnsToAPITranslation],
  props: {
    currentUser: Object,
    studyId: Number,
    tags: Array,
    request: Object,
  },
  components: {
    Actions,
    AgGridVue,
    KeywordRenderer,
    PageRenderer,
    RichResultsRenderer,
    PositionRenderer,
    ActionsRenderer,
    OpportunityScoreRenderer,
  },
  data() {
    return {
      gridApi: null,
      columnApi: null,
      gridOptions: {
        defaultColDef: { sortable: true, resizable: true },
        onColumnVisible: () => this.adjustGrid(),
      },
      selectedKeywords: [],
      dialogVisible: false,
      taskDialogVisible: false,
      userOptions: null,
      precedentRequest: null,
      snippetValues: [
        "Images",
        "Video",
        "Videos",
        "Map",
        "Answer box",
        "Carousel",
        "People also ask",
        "Knowledge graph",
        "Local pack",
        "Google review",
        "Shopping",
        "Twitter",
        "Top stories",
        "Google flights",
        "Jobs",
        "Paid",
      ],
    };
  },
  computed: {
    selectedKeywordsPresent() {
      return this.selectedKeywords.length > 0;
    },
    columnDefs() {
      return [
        {
          headerName: this.$i18n.t("keyword"),
          field: "keyword",
          minWidth: 100,
          filter: "agTextColumnFilter",
          cellRendererFramework: "KeywordRenderer",
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          filterParams: {
            suppressAndOrCondition: true,
            applyButton: true,
            newRowsAction: "keep",
          },
          floatingFilter: true,
        },
        {
          headerName: this.$i18n.t("tags"),
          field: "tags",
          width: 125,
          valueFormatter: (params) => params.value.join("|"),
          filter: "agTextColumnFilter",
          hide: true,
        },
        {
          headerName: this.$i18n.t("rich_results"),
          suppressMenu: true,
          field: "richResults",
          minWidth: 100,
          maxWidth: 150,
          filter: "agSetColumnFilter",
          keyCreator: (params) => {
            if (params.value == null) {
              return [""];
            } else {
              return Object.keys(params.value).map((x) =>
                x.replace(/_/g, " ").toLowerCase()
              );
            }
          },
          filterParams: {
            suppressAndOrCondition: true,
            values: this.snippetValues,
            applyButton: true,
            newRowsAction: "keep",
          },
          cellRendererFramework: "RichResultsRenderer",
          sortable: false,
          floatingFilter: true,
        },
        {
          headerName: this.$i18n.t("page"),
          field: "page",
          minWidth: 100,
          filter: "agTextColumnFilter",
          cellRendererFramework: "PageRenderer",
          valueGetter: (params) => {
            if (params.data.assignedPage)
              return params.data.assignedPage
            return undefined
          },
          filterParams: {
            suppressAndOrCondition: true,
            newRowsAction: "keep",
            applyButton: true,
          },
          sortable: false,
          floatingFilter: true,
        },
        {
          headerName: this.$i18n.t("volume"),
          field: "volume",
          width: 125,
          valueFormatter: numberFormatter,
          type: "numericColumn",
          sort: "desc",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
            suppressAndOrCondition: true,
            applyButton: true,
            newRowsAction: "keep",
          },
          floatingFilter: true,
        },
        {
          headerName: this.$i18n.t("position"),
          field: "position",
          width: 125,
          valueGetter: (params) => {
            if (params.data) {
              return params.data.position ? params.data.position : 101;
            }
          },
          valueFormatter: positionFormatter,
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
            suppressAndOrCondition: true,
            applyButton: true,
            newRowsAction: "keep",
            filterOptions: [
              "equals",
              "greaterThan",
              "greaterThanOrEqual",
              "lessThan",
              "lessThanOrEqual",
              "inRange"
            ]
          },
          cellRendererFramework: "PositionRenderer",
          floatingFilter: true,
        },
        {
          headerName: this.$i18n.t("estimatedTraffic"),
          field: "estimatedTraffic",
          width: 125,
          valueFormatter: numberFormatter,
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
            suppressAndOrCondition: true,
            applyButton: true,
            newRowsAction: "keep",
          },
          hide: true,
          floatingFilter: true,
        },
        {
          headerName: this.$i18n.t("opportunity_score"),
          field: "opportunityScore",
          hide: true,
          width: 150,
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
            suppressAndOrCondition: true,
            applyButton: true,
            newRowsAction: "keep",
          },
          cellRendererFramework: "OpportunityScoreRenderer",
          floatingFilter: true,
        },
        {
          headerName: this.$i18n.t("actions"),
          headerClass: "ag-centered-header",
          minWidth: 130,
          maxWidth: 160,
          cellRendererFramework: "ActionsRenderer",
          cellClass: "text-center",
          filter: false,
          suppressMenu: true,
          sortable: false,
          floatingFilter: true,
        },
      ];
    },
  },
  watch: {
    request(value) {
      this.updateData();
      this.context.startDate = formatDateWithDashes(value.startDate);
      this.context.endDate = formatDateWithDashes(value.endDate);
    },
  },
  beforeMount() {
    this.context = {
      studyId: this.studyId,
      updateKeywordPage: this.updateKeywordPage,
      deleteKeyword: this.deleteKeyword,
      createTask: this.createTask,
      showKeyword: this.showKeyword,
      showTaskDialog: this.showTaskDialog,
      currentUser: this.currentUser,
      showPageDialog: this.showPageDialog,
      endDate: formatDateWithDashes(this.request.endDate),
      startDate: formatDateWithDashes(this.request.startDate)
    };
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;

      var fakeServer = this.createFakeServer();
      var datasource = this.createServerSideDatasource(
        this,
        fakeServer,
        this.request,
        this.studyId
      );
      this.gridApi.setServerSideDatasource(datasource);
    },
    updateData() {
      var fakeServer = this.createFakeServer();
      var datasource = this.createServerSideDatasource(
        this,
        fakeServer,
        this.request,
        this.studyId
      );
      this.gridApi.setServerSideDatasource(datasource);
    },
    adjustGrid() {
      if (this.gridApi) {
        this.gridApi.sizeColumnsToFit();
      }
    },
    onSelectionChanged(event) {
      this.selectedKeywords = event.api.getSelectedRows();
    },
    addTags(tags) {
      AddTagsRepository.createTagsAddition(
        this.studyId,
        this.selectedKeywords.map((k) => k.id),
        tags
      )
        .then((data) => {
          this.$message({
            message: this.$i18n.t("add_tags_success"),
            type: "success",
            duration: 6000,
          });
          this.updateData();
          this.$emit("refresh-data");
        })
        .catch((error) => {
          this.$message({
            message: this.$i18n.t("add_tags_fail"),
            type: "error",
            duration: 6000,
          });
          console.log(error);
        });
    },
    removeTags(tags) {
      RemoveTagsRepository.createTagsRemoval(
        this.studyId,
        this.selectedKeywords.map((k) => k.id),
        tags
      )
        .then((data) => {
          this.$message({
            message: this.$i18n.t("remove_tags_success"),
            type: "success",
            duration: 6000,
          });
          this.updateData();
          this.$emit("refresh-data");
        })
        .catch((error) => {
          this.$message({
            message: this.$i18n.t("remove_tags_fail"),
            type: "error",
            duration: 6000,
          });
          console.log(error);
        });
    },
    deleteKeywords() {
      if (confirm(this.$i18n.t("delete_keywords_confirmation"))) {
        RemoveKeywordsRepository.createRemoveKeywords(
          this.studyId,
          this.selectedKeywords.map((k) => k.id)
        )
          .then((data) => {
            this.$message({
              message: this.$i18n.t("delete_keywords_success"),
              type: "success",
              duration: 6000,
            });
            this.deselectAllRows();
            this.updateData();
            this.$emit("refresh-data");
          })
          .catch((error) => {
            this.$message({
              message: this.$i18n.t("delete_keywords_fail"),
              type: "error",
              duration: 6000,
            });
            console.log(error);
          });
      }
    },
    deleteKeyword(keywordId) {
      RemoveKeywordsRepository.createRemoveKeywords(this.studyId, [keywordId])
        .then((data) => {
          this.$message({
            message: this.$i18n.t("delete_keywords_success"),
            type: "success",
            duration: 6000,
          });
          this.deselectAllRows();
          this.updateData();
          this.$emit("refresh-data");
        })
        .catch((error) => {
          this.$message({
            message: this.$i18n.t("delete_keywords_fail"),
            type: "error",
            duration: 6000,
          });
          console.log(error);
        });
    },
    exportSelectedKeywords() {
      const params = {
        skipHeader: false,
        columnKeys: [
          "keyword",
          "tags",
          "page",
          "volume",
          "position",
          "opportunityScore",
        ],
        onlySelected: true,
        fileName: "Keywords export",
        sheetName: "Keywords",
        processCellCallback: (params) => {
          if (params.value && params.column.colDef.field.includes("position")) {
            if (params.value > 100) {
              return "> 100";
            } else {
              return params.value;
            }
          } else {
            if (params.value && params.column.colId == "page") {
              if (params.node.data.position > 100)
                return this.$i18n.t("noPagePositioned");
            }
            if (params.value && params.column.colId == "tags") {
              return params.value.join("|");
            }
            return params.value;
          }
        },
      };
      this.gridApi.exportDataAsExcel(params);
    },
    exportSemanticSelectedKeywords() {
      KeywordsExportsRepository.createSemanticalExport(
        this.studyId,
        this.getFilteredKeywordIds(),
        "xlsx"
      )
        .then((data) => {
          this.$message({
            message: this.$i18n.t("export_keywords_success"),
            type: "success",
            duration: 6000,
            offset: 80,
          });
        })
        .catch((error) => {
          this.$message({
            message: this.$i18n.t("export_keywords_fail"),
            type: "error",
            duration: 6000,
            offset: 80,
          });
          console.log(error);
        });
    },
    updateKeywordPage(keywordId, url, rowNode) {
      var gridRow = this.gridApi.getRowNode(rowNode.id);

      KeywordPagesRepository.updatePage(this.studyId, keywordId, url)
        .then((url) => {
          gridRow.data.assignedPage = url;
          this.gridApi.refreshCells();
          this.$message({
            message: this.$i18n.t("url_updated_success"),
            type: "success",
            duration: 6000,
          });
        })
        .catch((error) => {
          this.$message({
            message: this.$i18n.t("url_updated_failure"),
            type: "error",
            duration: 6000,
          });
          console.log(error);
        });
    },
    createTask(keywordId, keywordText) {
      const taskTitle = this.$i18n.t("work_on_keyword", {
        keywordText: keywordText,
      });
      const task = { title: taskTitle, state: "pending", keywordId: keywordId };
      this.$api
        .post(`/studies/${this.studyId}/tasks`, task)
        .then(() => {
          this.$message({
            message: this.$i18n.t("task_created_success"),
            type: "success",
            duration: 6000,
          });
        })
        .catch((error) => {
          this.$message({
            message: this.$i18n.t("task_created_failure"),
            type: "error",
            duration: 6000,
          });
          console.log(error);
        });
    },
    getFilteredRows() {
      const selectedNodes = this.gridApi.getSelectedNodes();
      if (selectedNodes.length > 0) {
        return selectedNodes;
      }

      const rows = [];
      this.gridApi.forEachNodeAfterFilter((node, index) => rows.push(node));
      return rows;
    },
    getFilteredKeywordIds() {
      return this.getFilteredRows().map((node) => node.data.id);
    },
    showKeyword(keywordId) {
      this.$emit("show-keyword", keywordId);
    },
    showTaskDialog(keywordId, keywordText) {
      this.$emit("show-task", { id: keywordId, text: keywordText });
    },
    deselectAllRows() {
      this.gridApi.deselectAll();
    },
    //Override grid pagination mixin
    selectAllRows() {
      const indexFirstDisplayedRow = this.getFirstIndex();
      const lastDisplayedRow = this.getLastIndex() - 1;

      var allDisplayedAreSelected = true;
      this.gridApi.forEachNode((node, index) => {
        if (index <= lastDisplayedRow && index >= indexFirstDisplayedRow) {
          if (!node.isSelected()) allDisplayedAreSelected = false;
        }
      });

      this.gridApi.forEachNode((node, index) => {
        if (index <= lastDisplayedRow && index >= indexFirstDisplayedRow) {
          node.setSelected(!allDisplayedAreSelected, false);
        }
      });
    },
    createServerSideDatasource(context, server, request, studyId) {
      return {
        getRows: function (params) {
          server
            .getData(context, params.request, request, studyId)
            .then((response) => {
              params.parentNode.gridApi.showLoadingOverlay();
              setTimeout(function () {
                params.parentNode.gridApi.hideOverlay();
                if (response.success) {
                  params.successCallback(response.rows, response.lastRow);
                } else {
                  params.failCallback();
                  params.parentNode.gridApi.showNoRowsOverlay();
                }
              }, 500);
            });
        },
      };
    },
    createFakeServer(allData) {
      return {
        getData(context, options, request, studyId) {
          context.$emit("is-loading", true);

          let requestedRows;
          let lastRow;
          let requestToSend = context.createRequest(options, request);
          if (context.precedentRequest != null) {
            if (
              context.shouldResetRowSelection(
                requestToSend,
                context.precedentRequest
              )
            ) {
              context.deselectAllRows();
            }
          }
          context.precedentRequest = requestToSend;
          return context.$api
          .post(`/studies/${studyId}/positions/index`, requestToSend)
          .then((response) => {
            const data = response.data;
            requestedRows = data.data.map((keyword) => {
                return {
                  id: keyword.id,
                  keyword: keyword.text,
                  page: keyword.position ? keyword.position.url : null,
                  assignedPage: keyword.positionAssigned
                    ? keyword.positionAssigned.url
                    : null,
                  volume: parseInt(keyword.volume),
                  position: keyword.position ? keyword.position.position : null,
                  pastPosition: keyword.pastPosition
                    ? keyword.pastPosition.position
                    : null,
                  richResults: keyword.serpResultType,
                  tags: keyword.tags,
                  opportunityScore: keyword.opportunityScore,
                  status: keyword.status,
                  estimatedTraffic: keyword.position
                    ? keyword.position.estimatedTraffic
                    : 0,
                };
              });
              lastRow = getLastRowIndex(options, requestedRows);

              context.$emit("is-loading", false);

              if (data.data.length == 0) {
                return {
                  success: false,
                };
              }
              return {
                success: true,
                rows: requestedRows,
                lastRow: lastRow,
              };
            });
        },
      };
    },
    shouldResetRowSelection(request, precedentRequest) {
      var result = {};
      let tempRequest = { ...request };
      let tempPrecedentRequest = { ...precedentRequest };
      if (tempRequest.offset == tempPrecedentRequest.offset) {
        if (
          JSON.stringify(tempRequest) === JSON.stringify(tempPrecedentRequest)
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        delete tempRequest.limit;
        delete tempRequest.offset;
        delete tempPrecedentRequest.limit;
        delete tempPrecedentRequest.offset;
        if (
          JSON.stringify(tempRequest) === JSON.stringify(tempPrecedentRequest)
        ) {
          return false;
        } else {
          return true;
        }
      }
    },
    createRequest(options, request) {
      let requestApi = {
        offset: options.startRow,
        limit: options.endRow,
        date: formatDateWithDashes(request.endDate),
        pastDate: formatDateWithDashes(request.startDate),
        frequency: request.frequency === "DAY" ? "DAILY" : "WEEKLY",
        searchEngineParameters: { device: request.searchEngine.device },
      };

      if (request.tagsGroups && request.tagsGroups.length > 0) {
        let tempTagsGroups = this.request.tagsGroups.slice();
        tempTagsGroups.forEach((tagsGroup, index, object) => {
          if (tagsGroup.length === 0) {
            object.splice(index, 1);
          }
        });
        if (tempTagsGroups.length > 0) {
          requestApi.tagsGroups = tempTagsGroups;
        }
      }

      if (options.sortModel.length > 0) {
        requestApi.sortBy = {};
        requestApi.sortBy.fieldName = options.sortModel[0].colId
          .replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
          .toUpperCase();
        requestApi.sortBy.order = options.sortModel[0].sort.toUpperCase();
      }
      if (options.filterModel != null) {
        Object.keys(options.filterModel).forEach((column, index) => {
          let filter = {};
          if (options.filterModel[column].filterType !== "set") {
            filter.type = this.getFilterTypeForApi(
              options.filterModel[column].type
            );

            if (
              options.filterModel[column].filterType === "number" &&
              options.filterModel[column].type === "inRange"
            ) {
              filter.min = options.filterModel[column].filter;
              filter.max = options.filterModel[column].filterTo;
            } else {
              filter.value = options.filterModel[column].filter;
            }
          }
          switch (column) {
            case "volume":
              requestApi.volume = filter;
              break;
            case "position":
              requestApi.position = filter;
              break;
            case "opportunityScore":
              requestApi.opportunityScore = filter;
              break;
            case "page":
              requestApi.page = filter;
              break;
            case "keyword":
              requestApi.keyword = filter;
              break;
            case "richResults":
              requestApi.serpResultTypes = options.filterModel[
                column
              ].values.map((value) => upperSnakeCase(value));
              break;
          }
        });
      }
      return requestApi;
    },
    showPageDialog(value) {
      this.$emit("show-page", value);
    },
  },
};

function upperSnakeCase(str) {
  const strArr = str.split(" ");
  const snakeArr = strArr.reduce((acc, val) => {
    return acc.concat(val.toUpperCase());
  }, []);
  return snakeArr.join("_");
}

function getLastRowIndex(options, results) {
  if (!results) return undefined;
  var currentLastRow = options.startRow + results.length;
  return currentLastRow < options.endRow ? currentLastRow : undefined;
}

let positionFormatter = (params) => {
  if (params.value < 101) {
    return params.value;
  } else {
    return "> 100";
  }
};

let formatNumber = (number) => {
  return Math.floor(number)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
};

let numberFormatter = (params) => formatNumber(params.value);
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.flex {
  display: flex;
}

.grid-pagination {
  margin-top: 1rem;
  text-align: right;

  .divider {
    width: 2px;
  }

  .value-page {
    margin-right: 0.7rem;
    margin-left: 0.7rem;
  }
}

::v-deep .rich-results {
  i {
    margin-right: 0.25rem;
  }
}

::v-deep .muted {
  color: $--color-text-placeholder;
}

::v-deep .ag-centered-header {
  .ag-header-cell-label {
    justify-content: center;
  }
}

::v-deep .ag-stub-cell {
  display: none;
}

::v-deep .is-present {
  color: $--color-success;
}
</style>

<i18n src="@/javascripts/grid.json"></i18n>
<i18n>
{
  "en": {
    "estimatedTraffic": "Estimated traffic",
    "keyword": "Keyword",
    "tags": "Tags",
    "rich_results": "Snippets",
    "page": "Page",
    "volume": "Volume",
    "position": "Rank",
    "opportunity_score": "Opportunity score",
    "actions": "Actions",
    "add_tags_success": "Tags were added successfully.",
    "add_tags_fail": "Adding tags failed.",
    "remove_tags_success": "Tags were removed successfully.",
    "remove_tags_fail": "Removing tags failed.",
    "delete_keywords_confirmation": "Are you sure you want to delete these keywords ?",
    "delete_keywords_success": "Keywords are being deleted, it may take a few minutes",
    "delete_keywords_fail": "Keywords deletion failed.",
    "export_keywords_success": "Keywords were successfully exported, an email was sent to you with the export file.",
    "export_keywords_fail": "Keywords export failed.",
    "url_updated_success": "Page sucessfully updated",
    "url_updated_failure": "Error during page update",
    "work_on_keyword": "Work on keyword: %{keywordText}",
    "task_created_success": "Task created successfully",
    "task_created_failure": "Task creation failed",
    "noPagePositioned": "No page positioned"
  },
  "fr": {
    "estimatedTraffic": "Trafic estimé",
    "keyword": "Mot clé",
    "tags": "Groupes",
    "rich_results": "Snippets",
    "page": "Page",
    "volume": "Volume",
    "position": "Position",
    "opportunity_score": "Score d'opportunité",
    "actions": "Actions",
    "add_tags_success": "Les groupes ont été ajoutés avec succès.",
    "add_tags_fail": "L'ajout de groupes a échoué.",
    "remove_tags_success": "Les groupes ont été retirés avec succès.",
    "remove_tags_fail": "Le retrait de groupes a échoué.",
    "delete_keywords_confirmation": "Êtes-vous sûr de vouloir supprimer ces mots clés ?",
    "delete_keywords_success": "La suppression des mots-clés est en cours, cette opération peut prendre quelques minutes.",
    "delete_keywords_fail": "La suppression des mots clés a échoué.",
    "export_keywords_success": "Les mots clés ont été exporté avec succès, un email vous a été envoyé avec le fichier d'export.",
    "export_keywords_fail": "L'export de mots clés a échoué.",
    "work_on_keyword": "Travailler le mot clé : %{keywordText}",
    "url_updated_success": "La nouvelle page a été assignée",
    "url_updated_failure": "Erreur lors de l'assignation de la page",
    "task_created_success": "Tâche créée avec succès",
    "task_created_failure": "La création de la tâche a échoué",
    "noPagePositioned": "Pas de page positionnée"
  },
  "de": {
    "estimatedTraffic": "Geschätzter Verkehr",
    "keyword": "Stichwort",
    "tags": "Gruppen",
    "rich_results": "Ausschnitte",
    "page": "Buchseite",
    "volume": "Volumen",
    "position": "Position",
    "opportunity_score": "Opportunity-Score",
    "actions": "Aktionen",
    "add_tags_success": "Die Gruppen wurden erfolgreich hinzugefügt.",
    "add_tags_fail": "Gruppen konnten nicht hinzugefügt werden.",
    "remove_tags_success": "Die Gruppen wurden erfolgreich entfernt.",
    "remove_tags_fail": "Gruppen konnten nicht entfernt werden.",
    "delete_keywords_confirmation": "Möchten Sie diese Keywords wirklich entfernen?",
    "delete_keywords_success": "Keywords werden gerade gelöscht, dieser Vorgang kann einige Minuten dauern.",
    "delete_keywords_fail": "Keywords konnten nicht entfernt werden.",
    "export_keywords_success": "Die Keywords wurden erfolgreich exportiert, Sie haben eine E-Mail mit der Exportdatei erhalten.",
    "export_keywords_fail": "Export der Keywords fehlgeschlagen.",
    "work_on_keyword": "Bearbeiten Sie das Schlüsselwort: %{keywordText}",
    "url_updated_success": "Die neue Seite wurde zugewiesen",
    "url_updated_failure": "Fehler beim Zuweisen der Seite",
    "task_created_success": "Aufgabe erfolgreich erstellt",
    "task_created_failure": "Aufgabenerstellung fehlgeschlagen",
    "noPagePositioned": "Keine Seite positioniert"
  }
}
</i18n>
